.container {
  border-radius: 0px;
  border-radius: var(--spacing-20);
}

.button {
  cursor: pointer;
  border: none;
  padding: var(--spacing-40) var(--spacing-50);
  width: 100%;
  border-radius: var(--spacing-20);
  color: var(--color-primary);
}
.button:focus {
  outline: none;
}

.text {
  background-color: transparent;
}
.text:hover {
  background-color: var(--color-primary-background);
  color: var(--color-primary-light);
}
.text:disabled {
  color: var(--color-dull);
  background-color: transparent;
}

.link {
  padding: var(--spacing-00);
  background-color: transparent;
  line-height: 20px;
}
.link:hover {
  color: var(--color-primary-light);
}
.link:disabled {
  color: var(--color-dull);
  background-color: transparent;
}

.outline {
  border: solid 1px;
  border-color: var(--color-primary);
}
.outline:hover {
  background-color: var(--color-primary-background);
}
.outline:disabled {
  color: var(--color-grey-040);
  cursor: default;
  pointer-events: none;
  border-color: var(--color-grey-040);
}

.contained {
  border: solid 1px;
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-white);
}

.contained:hover {
  background-color: var(--color-primary-light);
}

.contained:disabled {
  background-color: var(--color-dull);
  border-color: var(--color-dull);
  cursor: default;
  pointer-events: none;
}

.pushTop {
  margin-top: calc(-1 * var(--spacing-50));
}
.pushRight {
  margin-right: calc(-1 * var(--spacing-50));
}
.pushBottom {
  margin-bottom: calc(-1 * var(--spacing-50));
}
.pushLeft {
  margin-left: calc(-1 * var(--spacing-50));
}
